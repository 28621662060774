<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab2"
            class="
              weui-tab__bd-item weui-tab__bd-item--active
              public-prizes-historys
            "
          >
            <div class="public-prizes-historys-list">
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell public-prizes-history-item" v-for="(item, index) in list" :key="index">
                    <div class="weui-cell__hd">
                      <img class="" src="../assets/pay-logo-alipay.jpg" />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">{{item.paymentChannel === 'ALP' ?  '支付宝' : '微信'}}现金{{item.amount}}元</p>
                      <p class="remark">{{item.createTime | timeFormat('YYYY-MM-DD HH:mm:ss')}}<br /></p>
                      <p class="remark" v-if="item.remark">{{item.remark}}</p>
                    </div>
                    <div class="weui-cell__ft">
                      <p class="status text-green" v-if="item.trsStatus === 'A'">已受理</p>
                      <p class="status text-green" v-else-if="item.trsStatus === 'P'">处理中</p>
                      <p class="status text-green" v-else-if="item.trsStatus === 'S'">已发放</p>
                      <p class="status text-red" v-else-if="item.trsStatus === 'F'">失败</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="['weui-loadmore', 'public-loadmore', {'hidden' : !isShowBottomLoading}]">
              <i class="weui-loading"></i>
              <span class="weui-loadmore__tips">正在加载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>
<script>
import throttle from 'lodash/throttle'
import { redeemRecord } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
export default {
  name: "prizeHistory",
  components: {
    tabbar
  },
  data () {
    return {
      pageNum: 1,
      pageSize: 20,
      pageCount: 1, //	总共页数
      list: [], // 明细列表
      isShowBottomLoading: false
    }
  },
  watch: {
    pageNum: function () {
      this.doGetredeemRecord()
    }
  },
  created () {
    this.doGetredeemRecord()
    this.listenScroll()
  },
  methods: {
    // 提交用户信息
    async doGetredeemRecord () {
      let params = {
        param: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      let res = {}
      try {
        res = await redeemRecord(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          const list = result.userRedeemRecordRecordList
          this.list.push(...list)
          this.pageCount = result.pageCount
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 页面已经滑动到底部
    pageToBottom () {
      if (this.pageNum < this.pageCount) {
        this.pageNum++
      }
    },
    listenScroll () {
      const _this = this
      window.onscroll = throttle(function (e) {
        console.log('e:', e)
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let screenH = document.documentElement?.clientHeight || window.innerHeight
        let bodyH = document.body.scrollHeight
        if ((scrollTop + screenH + 10 - bodyH) > 0 ) {
          console.log('页面已经滑动到底部')
          _this.pageToBottom()
        }
      }, 500)
    }
  }
};
</script>
<style lang="css" scoped>
.weui-cell__hd img {
  width: 1.5rem;
}
.public-container{
  margin: unset;
}
</style>
